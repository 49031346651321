<template>
  <div class="datum">
    <div v-if="list.length !== 0">
      <div class="datum_item" v-for="(item, index) in list" :key="index">
        <div class="item_img">
          <el-image
            :src="require('@/assets/img/partner/single/pdf.png')"
          ></el-image>
        </div>
        <div class="item_name">{{ item.kcName }}</div>
        <div class="item_btn" @click="examination(item)">免费试看</div>
      </div>
    </div>
    <div class="noMsg" v-if="list.length === 0">暂无内容~</div>
  </div>
</template>

<script>
import { Know } from "@/api/know";
let know = new Know();
export default {
  props: {
    classId: {
      type: String,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getClassesMaterials();
  },
  methods: {
    async getClassesMaterials() {
      const res = await know.getCoursePackageMaterialsList(this.classId);
      this.list = res.data.docPackage;
    },
    /* 免费试看 */
    examination(item) {
      this.$router.push({
        path: "/VIP/sourcePage",
        query: {
          seeNum: item.seeNum,
          names: item.kcName ? item.kcName : item.url,
          id: this.id,
          type: 1,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.datum {
  width: 100%;
  height: 457px;
  overflow-y: auto;
  margin-top: 20px;
  .datum_item {
    width: 624px;
    height: 60px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    opacity: 1;
    padding: 15px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 10px;
    .item_img {
      width: 24px;
      height: 26px;
      /deep/ .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .item_name {
      width: 450px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin: 0 20px 0 12px;
    }
    .item_btn {
      width: 88px;
      height: 30px;
      opacity: 1;
      border: 1px solid #00a2eb;
      border-radius: 5px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #00a2eb;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .noMsg {
    text-align: center;
    line-height: 400px;
  }
}
</style>
