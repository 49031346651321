<template>
  <div class="assistant">
    <div v-if="list.length !== 0">
      <div class="assistant_item" v-for="(item, index) in list" :key="index">
        <div class="item_left">
          <el-image :src="item.typeImg"></el-image>
        </div>
        <div class="item_right">
          <div class="right_name">
            <div class="name">{{ item.textbookName }}</div>
            <div class="tip">
              简介：<span v-html="ImgSizeLimit(item.textBookNote)"></span>
            </div>
          </div>
          <div class="right_btn">
            <div class="look" @click="seeTextbook(item)">免费试看</div>
            <div class="detail" @click="goTextbookDetails(item)">教材详情</div>
          </div>
        </div>
      </div>
    </div>
    <div class="noMsg" v-if="list.length === 0">暂无内容~</div>
  </div>
</template>

<script>
import { getClassTeaching, getClassCourseTeaching } from "@/api/home.js";
export default {
  props: {
    classId: {
      type: String,
    },
  },
  data() {
    return {
      list: [],
    };
  },
  created() {
    this.getClassTeaching();
  },
  methods: {
    /* 教材列表 */
    getClassTeaching() {
      getClassTeaching(this.classId).then((res) => {
        console.log("=============", res);
        this.list = res.msg;
      });
    },
    seeTextbook(item) {
      const userInfo = getInfo();
      if (userInfo) {
        this.$router.push({
          path: "/VIP/textbookPage",
          query: {
            IsPurchase: this.IsPurchase,
            id: item.id,
            classesId: this.classesId,
            title: this.title,
          },
        });
      } else {
        Vue.prototype.goLoginView(false);
      }
    },
    /* 点击教材详情 */
    goTextbookDetails(item) {
      this.$router.push({
        path: "/typeclassxq/textbookDetails",
        query: {
          id: item.id,
          title: this.title,
          classesId: this.classesId,
          IsPurchase: this.IsPurchase,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.assistant {
  width: 100%;
  height: 457px;
  overflow-y: auto;
  margin-top: 20px;
  .assistant_item {
    width: 624px;
    height: 108px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    opacity: 1;
    margin: 0 auto;
    display: flex;
    padding: 0 20px;
    margin-bottom: 20px;
    .item_left {
      width: 74px;
      height: 100px;
      position: relative;
      top: -10px;
      /deep/ .el-image {
        width: 100%;
        height: 100%;
      }
    }
    .item_right {
      width: calc(100% - 74px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      .right_name {
        width: 361px;
        margin-left: 14px;
        .name {
          font-size: 14px;
          font-family: PingFang SC-Bold, PingFang SC;
          font-weight: bold;
          color: #333333;
          margin-bottom: 10px;
        }
        .tip {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #818395;
          white-space: normal;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
      .right_btn {
        .look {
          width: 88px;
          height: 30px;
          //   box-shadow: 0px 3px 10px 1px rgba(0, 162, 235, 0.3);
          opacity: 1;
          border: 1px solid #00a2eb;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #00a2eb;
          border-radius: 5px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        .detail {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #777a82;
          margin-top: 10px;
          margin-left: 16px;
          cursor: pointer;
        }
      }
    }
  }
  .noMsg {
    text-align: center;
    line-height: 400px;
  }
}
</style>
