<template>
  <div class="educateWrap">
    <div class="serve">
      <div class="title">
        <div class="color-FF5E51"></div>
        <span>考前答疑</span>
      </div>
    </div>
    <div class="serve">
      <div class="title">
        <div class="color-FF5E51"></div>
        <span>专属服务</span>
      </div>
      <div class="main">
        <!-- v-show="jfType.length > 0" -->
        <div class="mainItam">
          <!-- v-if="item == '考试通知'" -->
          <img class="mainItamIcon" src="@/assets/img/Home/notice.png" />
          <!-- v-if="item == '督学跟踪'" -->
          <img class="mainItamIcon" src="@/assets/img/Home/track.png" />
          <!-- v-if="item == '定期班会'" -->
          <img class="mainItamIcon" src="@/assets/img/Home/regular.png" />
          <!-- v-if="item == '在线答疑'" -->
          <img class="mainItamIcon" src="@/assets/img/Home/answer.png" />
          <span>{{ item }}</span>
        </div>
      </div>
    </div>
    <div class="details">
      <div class="title">
        <div class="color-FF5E51"></div>
        <span>教服详情</span>
      </div>
      <div v-html="ImgSizeLimit(jfServer)"></div>
    </div>
    <!-- <div class="classMeeting">
                    <div class="title">
                        <div class="color-FF5E51"></div>
                        <span>班会</span>
                    </div>
                    <div class="main">
                        <div 
                            class="mainItem" 
                            v-for="item,index in classMeetingList"
                            :key="index"
                        >
                            <div class="mainItemTitle" v-if="item.index == 1">
                                <img class="img" src="@/assets/img/kecheng/Group1339.png"/>
                                <span >正在直播中</span>
                            </div>
                            <div class="mainItemTitle" v-if="item.index == 2">
                                <span >距直播开始：</span>
                                <div class="block">02</div>
                                <span>：</span>
                                <div class="block">24</div>
                                <span>：</span>
                                <div class="block">32</div>
                            </div>
                            <div class="mainItemTitle" v-if="item.index == 3">
                                <span >距直播开始：</span>
                                <div class="block">02</div>
                                <span>：</span>
                                <div class="block">24</div>
                                <span>：</span>
                                <div class="block">32</div>
                                <div class="right">
                                    <span>
                                        预约成功
                                    </span>
                                </div>
                            </div>
                            <div class="mainItemTitle" v-if="item.index == 4">
                                <span >直播已结束</span>
                            </div>

                            <div class="contentItem">
                                <el-image class="image"></el-image>
                                <div class="contentMain">
                                    <div class="contentText">
                                        <div class="contentTitle">{{item.value}}</div>
                                        <div class="contentTime">{{`直播时间：${item.time}`}}</div>
                                    </div>
                                    <div class="contentBtn">
                                        <span>{{ `${item.people}人预约` }}</span>
                                        <el-button class="btn" size="mini" type="danger" v-if="item.index == 1">立即观看</el-button>
                                        <el-button class="btn" size="mini" type="danger" v-else-if="item.index == 2">立即预约</el-button>
                                        <el-button class="btn" size="mini" type="info" v-else-if="item.index == 3" disabled>立即观看</el-button>
                                        <el-button class="btn" size="mini" v-else>观看回放</el-button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      classMeetingList: [
        {
          index: 1,
          value: "2021年元宇宙概念班会-第二期",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 2,
          value: "2021年元宇宙概念班会-第二期",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 3,
          value: "2021年元宇宙概念班会",
          time: "2021-12-10 12:30",
          people: 3000,
        },
        {
          index: 4,
          value: "2021年元宇宙概念班会",
          time: "2021-12-10 12:30",
          people: 3000,
        },
      ],
      jfServer: null,
      jfType: [],
    };
  },
  methods: {
    init(jfServer, detalilsList) {
      this.jfServer = jfServer;
      this.jfType = detalilsList.jfType.split(",");
    },
    /* 关闭回调 */
    educateClose() {
      this.$emit("educateClose");
    },
  },
};
</script>

<style lang="less" scoped>
.educateWrap {
  padding-top: 32px;
  width: 100%;
  //   height: 500px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .serve {
    width: 614px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 20px;
    padding: 16px 20px;

    .main {
      display: flex;
      .mainItam {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 19px;
        /* text-align: center; */
        /* line-height: 80px; */
        width: 186px;
        height: 80px;
        background-color: #f5f7f9;
        font-size: 18px;
        font-family: Microsoft YaHei-Regular;
        border-radius: 8px;
        .mainItamIcon {
          width: 24px;
          height: 24px;
        }
      }
    }
  }
  .title {
    display: flex;
    align-items: center;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    margin-bottom: 20px;
    .color-FF5E51 {
      background-color: #ff5e51;
      width: 3px;
      height: 14px;
      margin-right: 8px;
    }
  }
  .details {
    width: 614px;
    background: #ffffff;
    box-shadow: 0px 0px 6px 1px rgba(0, 0, 0, 0.06);
    border-radius: 10px;
    opacity: 1;
    margin-bottom: 20px;
    padding: 16px 20px;
    .image {
      width: 596px;
      height: 242px;
      border-radius: 8px;
    }
  }
  .classMeeting {
    .main {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      .mainItem {
        width: 596px;
        height: 156px;
        background-color: #eeeeee;
        border-radius: 8px;
        margin-bottom: 24px;
        padding: 18px 16px 0 16px;
        .mainItemTitle {
          display: flex;
          align-items: center;
          margin-bottom: 15px;
          color: #6c7079;
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          .img {
            margin-right: 4px;
          }
          .block {
            width: 16px;
            height: 16px;
            background-color: #ff5e51;
            color: #ffffff;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            border-radius: 2px;
          }
          .right {
            margin-left: 156px;
            font-size: 12px;
            color: #ff5e51;
          }
        }
        .contentItem {
          display: flex;
          .image {
            width: 138px;
            height: 92px;
            border-radius: 8px;
            margin-right: 12px;
          }
          .contentMain {
            display: flex;
            flex-direction: column;
            .contentText {
              margin-bottom: 22px;
              .contentTitle {
                font-size: 14px;
                font-weight: 500;
                color: #373a42;
                margin-bottom: 12px;
              }
              .contentTime {
                font-size: 12px;
                color: #6c7079;
              }
            }
            .contentBtn {
              font-size: 12px;
              color: #9fa4ad;
              display: flex;
              align-items: center;
              .btn {
                margin-left: 269px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
